import React from "react";
import Layout from "../../components/Layout/Layout";
import CommonSlider from "../../Common/CommonSlider";
import banner2 from "../../Assets/images/gall.webp";
import RentalGrid from "./RentalGrid";
function Rental() {
  return (
    <div>
      <Layout>
        <CommonSlider text={"Gallery"} image={banner2} />
        <RentalGrid />
      </Layout>
    </div>
  );
}

export default Rental;
