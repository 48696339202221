import React from "react";
import Layout from "../Layout/Layout";
import banner from "../../Assets/images/reso4.png";
import CommonSlider from "../../Common/CommonSlider";
import SpecialGrid from "./SpecialGrid";
import s1 from "../../Assets/images/s1.png";
import s2 from "../../Assets/images/s2.png";
import s3 from "../../Assets/supdate.png";

function Specials() {
  const data = [
    {
      img: s1,
      heading: "Seasonal Retreats:",
      para: "Experience the magic of each season with our Seasonal Retreats. Each season, from winter wonderlands to blooming springs, brings unique beauty and activities to enjoy. Our packages include seasonal delights such as snowshoeing in winter, wildflower walks in spring, stargazing in summer, and fall foliage tours. Embrace the changing seasons and immerse yourself in nature's wonders.",
    },
    {
      img: s2,
      heading: "Extended Stay Savings:",
      para: "Stay longer, save more! With our Extended Stay Savings, the longer you stay, the bigger the discount. Whether you need a peaceful retreat to recharge or a remote workspace for a working vacation, our extended stay offers make it more affordable than ever. Indulge in the luxury of time and let the beauty of Grand View Getaway captivate you.",
    },
    {
      img: s3,
      heading: "Romantic Escapes:",
      para: "Surprise your loved one with a Romantic Escape to remember. Our special package includes a candlelit dinner for two, a relaxing couples' massage, and a bottle of champagne to toast to your love. Enjoy leisurely walks along scenic trails, stargazing by the firepit, and intimate moments amidst nature's beauty. Reconnect and cherish your bond in this enchanting and intimate setting.",
    },
  ];
  return (
    <>
      <Layout>
        <CommonSlider text={"Specials"} image={banner} show={true} />
        {data.map(({ heading, para, img }) => (
          <SpecialGrid head={heading} desc={para} img={img} />
        ))}
      </Layout>
    </>
  );
}

export default Specials;

