import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";

import Slider from "./Slider";
import About from "./About";
import MyCard from "../../components/Cards/MyCard";
import axios from "axios";
import MyCalendar from "../../components/CustomCalender/CustomCalender";
import Testimonials from "./Testimonials";
const Home = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://tcc-server-virid.vercel.app/icalendar"
        );
        const eventsData = response.data.map((event) => ({
          title: event.summary,
          start: new Date(event.dtstart),
          end: new Date(event.dtend),
          description: event.description,
          // You can include other event properties here
        }));

        setEvents(eventsData);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <Layout>
        <Slider />
        <About />
        {events.length > 0 && <MyCalendar events={events}></MyCalendar>}
        <MyCard />
        {/* <SliderComp /> */}
        <Testimonials />
        {/* <Box
          sx={{ height: "300px", width: "100%", backgroundColor: "white" }}
        ></Box> */}
        {/* <GetInTouch /> */}
      </Layout>
    </div>
  );
};

export default Home;
