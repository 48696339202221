import { Box } from "@mui/material";
import React from "react";
import Layout from "../Layout/Layout";
import banner from "../../Assets/images/bgs.jpg";
import GridSection from "../../Common/GridSection";

import CommonSlider from "../../Common/CommonSlider";
import Amenities from "./Amenities";
import { Helmet } from "react-helmet-async";

function Resort() {
  return (
    <>
      <Helmet>
        <title>
          Steelhead Fishing Clearwater River | Romantic Vacations in Lewiston
        </title>
        <meta name="description" content="Experience Thrilling Steelhead Fishing Clearwater River or Enjoy Romantic Vacations in Lewiston. Whether You're Seeking Adventure or A Peaceful Retreat, Our Destination Offers the Perfect Getaway for Couples and Anglers Alike."></meta>
      </Helmet>
      <Box>
        <Layout>
          <CommonSlider text={"VACATION hOME"} image={banner} />
          <GridSection />
          <Amenities />
        </Layout>
      </Box>
    </>
  );
}

export default Resort;

