import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa";
const styles = {
  cont: {
    maxWidth: { lg: "1200px", xl: "1500px" },
  },
  text: {
    color: "white",
    textAlign: "center",
    width: "100%",
    fontFamily: "Poppins,sans-serif",

    // marginBottom: "10px",
  },
  text2: {
    color: "white",
    fontFamily: "Poppins,sans-serif",
  },
  main: {
    backgroundColor: "#0D0D0D",
    paddingY: "30px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: { md: "start", xs: "center" },
    fontFamily: "Poppins,sans-serif",
    width: "100%",
  },
  row1: {
    display: "flex",
    flexDirection: { lg: "row", xs: "column" },
    alignItems: "center",
    fontFamily: "Poppins,sans-serif",
    gap: "12px",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  flex1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    gap: "10px",
    height: "50px",
    width: "50px",
    border: "1px solid white",
    color: "white",
    transition: "all ease-out 0.5s",
    ":hover": {
      color: "black",
      backgroundColor: "white",
    },
  },
  end: {
    display: "flex",
    justifyContent: { lg: "flex-end", xs: "center" },
    width: { lg: "80%" },
  },
  center: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "10px",
  },
  btn: {
    backgroundColor: "black",
    paddingY: "15px",
    paddingX: "30px",
    borderRadius: "30px",
    fontSize: "14px",
    border: "2px solid #1D528B",
    color: "white",
    fontFamily: "Poppins,sans-serif",
    transition: "all ease-out 0.5s",
    ":hover": {
      color: "#1D528B",
    },
  },
};
function Contactus() {
  return (
    <Box sx={styles.main}>
      <Container sx={styles.cont}>
        <Typography sx={[styles.text, { fontSize: "2.7rem", fontWeight: 500 }]}>
          STAY IN TOUCH
        </Typography>
        <Typography sx={[styles.text, { fontSize: "1.5rem" }]}>
          FILL IN YOUR DETAILS
        </Typography>

        <Grid
          container
          marginY={"20px"}
          spacing={5}
          sx={{ width: "100% !important" }}
        >
          <Grid item md={4} xs={12}>
            <input
              placeholder="Name *"
              style={{
                width: "100%",
                borderRadius: "100px",
                padding: "15px 15px",
                color: "white",
                backgroundColor: "#3D3D3D",
                border: "1px solid #3D3D3D",
                fontSize: "1.2rem",
                "::placeholder": {
                  color: "white", // Change this to the desired placeholder color
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <input
              placeholder="Phone *"
              style={{
                width: "100%",
                padding: "15px 15px",
                borderRadius: "100px",
                color: "white",
                backgroundColor: "#3D3D3D",
                border: "1px solid #3D3D3D",
                fontSize: "1.2rem",
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <input
              placeholder="Email *"
              style={{
                fontSize: "1.2rem",
                padding: "15px 15px",
                width: "100%",
                borderRadius: "100px",
                color: "#fff",
                backgroundColor: "#3D3D3D",
                border: "1px solid #3D3D3D",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <textarea
              placeholder="Type your message... "
              rows={20}
              style={{
                fontSize: "1.2rem",
                width: "100%",
                padding: "15px 20px",
                borderRadius: "40px",
                color: "white",
                opacity: "0.4",
                backgroundColor: "#3D3D3D",
                border: "1px solid #3D3D3D",
              }}
            />
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ marginLeft: "40px !important", marginTop: "15px !important" }}
          >
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Box sx={[styles.row, { gap: "20px" }]}>
                <Box sx={[styles.flex, { alignItems: "start" }]}>
                  <FaPhoneVolume style={{ color: "white", fontSize: 27 }} />
                </Box>
                <Box>
                  <Typography sx={styles.text2}>PHONE:</Typography>
                  <Typography sx={styles.text2}>+ 123 456 7890</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Box sx={[styles.row, { gap: "15px" }]}>
                <Box sx={styles.flex}>
                  <MarkunreadIcon sx={{ color: "white" }} />
                </Box>
                <Box>
                  <Typography sx={styles.text2}>EMAIL:</Typography>
                  <Typography sx={styles.text2}>Info@Donainname.Com</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} md={4} xs={12}>
              <Box sx={[styles.row, { gap: "15px" }]}>
                <Box sx={styles.flex}>
                  <AddLocationAltIcon sx={{ color: "white" }} />
                </Box>
                <Box>
                  <Typography sx={styles.text2}>LOCATION:</Typography>
                  <Typography sx={styles.text2}>
                    No 50 Baria Street 133, NewYork,Usa.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} xs={12}>
              <Box sx={styles.end}>
                <Button sx={styles.btn}>SUBMIT NOW</Button>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box sx={styles.center}>
              <Box sx={styles.flex1}>
                <FaFacebookF style={{ color: "inherit" }} />
              </Box>

              <Box sx={styles.flex1}>
                <TwitterIcon style={{ color: "inherit" }} />
              </Box>
              <Box sx={styles.flex1}>
                <FaLinkedinIn style={{ color: "inherit" }} />
              </Box>
              <Box sx={styles.flex1}>
                <InstagramIcon style={{ color: "inherit" }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Contactus;
