import React from "react";
import CommonSlider from "../../Common/CommonSlider";
import image from "../../Assets/images/inbanner02.png";
import Layout from "../../components/Layout/Layout";
import Contactus from "./Contactus";
function Contact() {
  return (
    <>
      <Layout>
        <CommonSlider text={"Contact Us"} image={image} />
        <Contactus />
      </Layout>
    </>
  );
}

export default Contact;
