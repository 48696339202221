import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

const Amenities = () => {

  const climateControl = [
    " Air conditioning",
    "Central air conditioning",
    "Heating",
    "Central heating",
  ];
  const CookingAndDining = [
    " Baking sheet",
    "Barbecue utensils",
    "Grill, charcoal, bamboo skewers/iron skewers, etc.",
    "BBQ grill",
    "Cooking basics (pots and pans, oil, salt, and pepper)",
    "Coffee maker (drip coffee maker, French press)",
    "Dishes and silverware (bowls, plates, cups, etc.)",
    "Microwave",
    "Oven",
    "Refrigerator",
    "Stove",
    "Toaster",
    "Blender",
  ];
  const CleaningAndMaintenance = [
    "Cleaning products",
    "Dishwasher",
    "Dryer",
    "Washer",
  ];
  const BathroomEssentials = [
    "Bathtub",
    "Body soap",
    "Conditioner",
    "Shampoo and conditioner",
    "Shower gel",
    "Towels",
    "Hot water",
    "Hair dryer",
  ];
  const SafetyAndSecurity = [
    " Carbon monoxide alarm",
    "Fire extinguisher",
    "First aid kit",
    "Smoke alarm",
    "Safe",
  ];
  const ComfortAndBedding = [
    "  Bed linens",
    "Extra pillows and blankets",
    "Hangers",
  ];
  const EntertainmentAndLeisure = [
    "Board games",
    "Books and reading material",
    "TV (HD • Amazon Prime Video, Disney+, Hulu, Max, Netflix)",
    "Wifi",
  ];
  const OutdoorAmenities = [
    "Fire pit",
    "Outdoor furniture",
    "Patio or balcony",
    "Right next to a body of water",
  ];
  const Kitchen = ["Space where guests can cook their own meals"];
  const Miscellaneous = [
    "Free parking on premises",
    "Room-darkening shades",
    "Wine glasses",
  ];

  // Split the items array into two parts

  return (
    <>
      <Box sx={{ background: "black", paddingY: "30px" }}>
        <Container sx={{ maxWidth: { lg: "1450px" } }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              color: "white",
              fontSize: { lg: "35px", sm: "25px", xs: "20px" },
              textAlign: "center",
            }}
          >
            Amenities
          </Typography>

          <Grid container spacing={2} sx={{ marginY: "15px" }}>
            <Grid item sm={4} xs={12}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  color: "white",
                  fontSize: { lg: "25px", sm: "25px", xs: "20px" },
                }}
              >
                Climate Control
              </Typography>
              <ul>
                {climateControl.map((item, index) => (
                  <li
                    style={{ color: "white", fontFamily: "Poppins" }}
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  color: "white",
                  fontSize: { lg: "25px", sm: "25px", xs: "20px" },
                }}
              >
                Bathroom essentials
              </Typography>
              <ul>
                {BathroomEssentials.map((item, index) => (
                  <li
                    style={{ color: "white", fontFamily: "Poppins" }}
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  color: "white",
                  fontSize: { lg: "25px", sm: "25px", xs: "20px" },
                }}
              >
                Outdoor amenities
              </Typography>
              <ul>
                {OutdoorAmenities.map((item, index) => (
                  <li
                    style={{ color: "white", fontFamily: "Poppins" }}
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  color: "white",
                  fontSize: { lg: "25px", sm: "25px", xs: "20px" },
                }}
              >
                Cooking and dining
              </Typography>
              <ul>
                {CookingAndDining.map((item, index) => (
                  <li
                    style={{ color: "white", fontFamily: "Poppins" }}
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  color: "white",
                  fontSize: { lg: "25px", sm: "25px", xs: "20px" },
                }}
              >
                Comfort and bedding
              </Typography>
              <ul>
                {ComfortAndBedding.map((item, index) => (
                  <li
                    style={{ color: "white", fontFamily: "Poppins" }}
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  color: "white",
                  fontSize: { lg: "25px", sm: "25px", xs: "20px" },
                }}
              >
                Kitchen
              </Typography>
              <ul>
                {Kitchen.map((item, index) => (
                  <li
                    style={{ color: "white", fontFamily: "Poppins" }}
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  color: "white",
                  fontSize: { lg: "25px", sm: "25px", xs: "20px" },
                }}
              >
                Cleaning and maintenance
              </Typography>
              <ul>
                {CleaningAndMaintenance.map((item, index) => (
                  <li
                    style={{ color: "white", fontFamily: "Poppins" }}
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  color: "white",
                  fontSize: { lg: "25px", sm: "25px", xs: "20px" },
                }}
              >
                Safety and security
              </Typography>
              <ul>
                {SafetyAndSecurity.map((item, index) => (
                  <li
                    style={{ color: "white", fontFamily: "Poppins" }}
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  color: "white",
                  fontSize: { lg: "25px", sm: "25px", xs: "20px" },
                }}
              >
                Entertainment and leisure
              </Typography>
              <ul>
                {EntertainmentAndLeisure.map((item, index) => (
                  <li
                    style={{ color: "white", fontFamily: "Poppins" }}
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  color: "white",
                  fontSize: { lg: "25px", sm: "25px", xs: "20px" },
                }}
              >
                Miscellaneous
              </Typography>
              <ul>
                {Miscellaneous.map((item, index) => (
                  <li
                    style={{ color: "white", fontFamily: "Poppins" }}
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Amenities;
