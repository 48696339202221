import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function Copright() {
  return (
    <Box sx={{ color: "white", backgroundColor: "black", paddingY: "1rem" }}>
      <Container sx={style.container}>
        <Box sx={style.box}>
          <Typography
            sx={{ color: "white", fontSize: { sm: "14px", md: "16px" } }}
          >
            Copyright © 2023 All Rights Reserved.
          </Typography>
          <Box sx={style.box1}>
            <Link
              to={"/terms-condition"}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: { sm: "14px", md: "16px" },
                  cursor: "pointer",
                }}
              >
                Terms and Condition
              </Typography>
            </Link>
            <Link
              to={"/privacy-policy"}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: { sm: "14px", md: "16px" },
                  cursor: "pointer",
                }}
              >
                Privacy Policy
              </Typography>
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Copright;
const style = {
  container: {
    maxWidth: { lg: "1400px" },
  },
  box: {
    display: "flex",
    flexDirection: { sm: "row", xs: "column" },
    justifyContent: { sm: "space-between", xs: "center" },
    alignItems: { sm: "center", xs: "center" },
    color: "white",
  },
  box1: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    color: "white",
  },
};
