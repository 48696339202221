import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import bgHome from "../../Assets/images/backHome.png";
const styles = {
  main: {
    width: "100%",

    paddingY: "40px",
  },
  absolute: {
    position: "absolute",
    top: 0,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  colr: {
    color: "#FFF",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: { lg: "48px", md: "35px" },
  },
  special: {
    textAlign: "center",
    color: "#0082CA",
    fontWeight: "600",
    fontFamily: "Roboto",
    fontSize: { lg: "30px", md: "25px" },
  },
  colr2: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "500",
  },
  colr3: {
    color: "#FFF",
    fontSize: "20px",
    fontWeight: "600",
    fontFamily: "Poppins",
  },
  cont: {
    marginY: "30px",
    borderRadius: "50px",
    maxWidth: { lg: "1200px", xl: "1500px" },
    paddingY: "50px",
    background: `url(${bgHome}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  grd: {
    width: "100%",

    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  col2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    alignItems: { md: "start", xs: "center" },
    gap: "25px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "30px",
  },
  col3: {
    display: "flex",
    flexDirection: "column",
  },
  btn: {
    backgroundColor: "#0082CA",
    paddingY: "12px",
    paddingX: "17px",
    color: "white",
    borderRadius: "25px",
    fontFamily: "Poppins",
    ":hover": {
      backgroundColor: "white",
      color: "#0082CA",
    },
  },
};

function SpecialGrid({ head, desc, img }) {
  return (
    <div>
      <Container sx={styles.cont}>
        <Grid container columnSpacing={"30px"}>
          <Grid item md={6}>
            <Box sx={styles.grd} data-aos="zoom-in-left">
              <Typography sx={styles.special} variant="h3" textAlign={"center"}>
                SPECIALS
              </Typography>
              <Typography sx={styles.colr} variant="h3" textAlign={"center"}>
                Grandview Getaway
              </Typography>
              <Box sx={styles.img}>
                <img src={img} style={{ height: "100%", width: "100%" }} alt="img" />
              </Box>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box sx={styles.col2} data-aos="zoom-in-right">
              <Typography sx={styles.colr3}>{head}</Typography>
              <Typography sx={styles.colr2}>{desc}</Typography>

              <Box sx={styles.row}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 20,
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <a class="button-2" role="button" href="/plan">
                    Direct Booking
                  </a>
                  <a
                    class="button-2"
                    role="button"
                    target="_blank"
                    href="https://t.vrbo.io/fZwwRWAkzCb"
                    rel="noreferrer"
                  >
                    Book On Vrbo
                  </a>
                  <a
                    class="button-2"
                    role="button"
                    target="_blank"
                    href="https://air.tl/MoXlLSAA"
                    rel="noreferrer"
                  >
                    Book On Airbnb
                  </a>
                </div>

                {/* <Box sx={styles.col3}>
                  <Typography sx={styles.colr2}>CALL US NOW</Typography>
                  <Typography sx={styles.colr3} textAlign={"left"}>
                    + 425-765-2031
                  </Typography>
                </Box> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default SpecialGrid;
