import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./CustomCalender.css";
const MyCalendar = ({ events }) => {
  const [date, setDate] = useState(new Date());

  const onChange = (newDate) => {
    setDate(newDate);
  };
  const tileDisabled = ({ date }) => {
    return events.some((event) => {
      const startDate = new Date(event.start);
      const endDate = new Date(event.end);
      return date >= startDate && date <= endDate;
    });
  };
  const tileContent = ({ date }) => {
    if (tileDisabled({ date })) {
      return <p>Reserved</p>;
    }
    return null;
  };
  return (
    <div
      data-aos="fade-right"
      data-aos-delay="1000"
      // style={{ width: window.innerWidth }}
      className="calenderBox"
    >
      {/* Set the width to 100% */}
      <h1 style={{ opacity: 0.7, fontWeight: 800, color: "white" }}>
        Reserves Your Dates
      </h1>
      <Calendar
        onChange={onChange}
        value={date}
        tileDisabled={tileDisabled}
        tileContent={tileContent}
      />
      <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
        <a class="button-2" role="button" href="/plan">
          Direct Booking
        </a>
        <a
          class="button-2"
          role="button"
          target="_blank"
          href="https://t.vrbo.io/fZwwRWAkzCb"
          rel="noreferrer"
        >
          Book On Vrbo
        </a>
        <a
          class="button-2"
          role="button"
          target="_blank"
          href="https://air.tl/MoXlLSAA"
          rel="noreferrer"
        >
          Book On Airbnb
        </a>
      </div>
    </div>
  );
};

export default MyCalendar;
