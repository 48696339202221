import React, { useState } from "react";
import { Box, Chip, Container, Grid } from "@mui/material";

import back from "../../Assets/images/backHome.png";

import extImg1 from "../../Assets/Interior/Interior.jpg";
import extImg2 from "../../Assets/Interior/Interior 2.jpg";
import extImg3 from "../../Assets/Interior/Interior 3.jpg";
import extImg4 from "../../Assets/Interior/Interior 4.jpg";
import extImg5 from "../../Assets/Interior/Interior 5.jpg";
import extImg6 from "../../Assets/Interior/27 copy.jpg";

// Bedrooms
import bedImg1 from "../../Assets/bedroom/bedroom.jpg";
import bedImg2 from "../../Assets/bedroom/bedroom 2.jpg";
import bedImg3 from "../../Assets/bedroom/bedroom 3.jpg";
import bedImg4 from "../../Assets/bedroom/bedroom 4.jpg";
import bedImg5 from "../../Assets/bed5.png";

// Bathrooms
import bathImg1 from "../../Assets/bathroom/bathroom.jpg";
import bathImg2 from "../../Assets/bathroom/bathroom 2.jpg";
import bathImg3 from "../../Assets/bathroom/bathroom 3.jpg";
import bathImg4 from "../../Assets/bathroom/bathroom 4.jpg";
import bathImg5 from "../../Assets/bathroom/Bathroom 6.jpg";

// Kitchen
import kitchenImg1 from "../../Assets/kitchen/kitchen 1.jpg";
import kitchenImg2 from "../../Assets/kitchen/kitchen 2.jpg";
import kitchenImg3 from "../../Assets/kitchen/kitchen 3.jpg";
import kitchenImg4 from "../../Assets/kitchen/kitchen 4.jpg";

// Living Area
import livingImg1 from "../../Assets/living area/living area.jpg";
import livingImg2 from "../../Assets/living area/living area 2.jpg";
import livingImg3 from "../../Assets/living area/living area 3.jpg";
import livingImg4 from "../../Assets/living area/living area 4.jpg";

// Amenities
import amenityImg1 from "../../Assets/Amenities/amenities 2.jpg";
import amenityImg2 from "../../Assets/Amenities/amenities 3.jpg";

// Views
import viewsImg1 from "../../Assets/View/view.jpg";
import viewsImg2 from "../../Assets/View/view2.jpg";
import viewsImg3 from "../../Assets/View/view 3.jpg";
import viewsImg4 from "../../Assets/View/view 4.jpg";

import n1 from "../../Assets/n1.jpg";
import n2 from "../../Assets/n2.jpg";
import n3 from "../../Assets/n3.jpg";
import n4 from "../../Assets/n4.jpg";
import n5 from "../../Assets/n5.jpg";
import n6 from "../../Assets/n6.jpg";
import n8 from "../../Assets/n8.jpg";
import n9 from "../../Assets/n9.jpg";
import n10 from "../../Assets/n10.jpg";
import n11 from "../../Assets/n11.jpg";
import n12 from "../../Assets/n12.jpg";
import n13 from "../../Assets/n13.jpg";
import n14 from "../../Assets/n14.jpg";
import n15 from "../../Assets/n15.jpg";
import n16 from "../../Assets/n16.jpg";
import n17 from "../../Assets/n17.jpg";
import n18 from "../../Assets/n18.jpg";
import n19 from "../../Assets/n19.jpg";
import n20 from "../../Assets/n20.jpg";
import n21 from "../../Assets/n21.jpg";
import n22 from "../../Assets/n22.jpg";
import n23 from "../../Assets/n23.jpg";
import n24 from "../../Assets/n24.jpg";
import n25 from "../../Assets/n25.jpg";
import n26 from "../../Assets/n26.jpg";
import n27 from "../../Assets/n27.jpg";
import n28 from "../../Assets/n28.jpg";
import n29 from "../../Assets/n29.jpg";
import n30 from "../../Assets/n30.jpg";
import n31 from "../../Assets/n31.jpg";
import n32 from "../../Assets/n32.jpg";
import n33 from "../../Assets/n33.jpg";
import n34 from "../../Assets/n34.jpg";
import n35 from "../../Assets/n35.jpg";
import n36 from "../../Assets/n36.jpg";

import b6 from "../../Assets/bedroom/b6.jpg";
import bath7 from "../../Assets/bathroom/bath7.jpg";
import bath8 from "../../Assets/bathroom/bath8.jpg";
import bath9 from "../../Assets/bathroom/bath9.jpg";

function RentalGrid() {
  const [current, setCurrent] = useState("All Photos");
  const data = [
    "All Photos",
    "Interior",
    "Bedrooms",
    "Bathroom",
    "Kitchen",
    "Living Area",
    "Amenities",
    "Views",
  ];
  const images = [
    //Interior
    {
      url: extImg1,
      category: "Interior",
    },
    {
      url: extImg2,
      category: "Interior",
    },
    {
      url: extImg3,
      category: "Interior",
    },
    {
      url: extImg4,
      category: "Interior",
    },
    {
      url: extImg5,
      category: "Interior",
    },
    {
      url: extImg6,
      category: "Interior",
    },
    // Bedrooms
    {
      url: bedImg1,
      category: "Bedrooms",
    },
    {
      url: bedImg2,
      category: "Bedrooms",
    },
    {
      url: bedImg3,
      category: "Bedrooms",
    },
    {
      url: bedImg4,
      category: "Bedrooms",
    },
    {
      url: bedImg5,
      category: "Bedrooms",
    },
    {
      url: b6,
      category: "Bedrooms",
    },

    // Bathroom
    {
      url: bathImg1,
      category: "Bathroom",
    },
    {
      url: bathImg2,
      category: "Bathroom",
    },
    {
      url: bathImg3,
      category: "Bathroom",
    },
    {
      url: bathImg4,
      category: "Bathroom",
    },
    {
      url: bathImg5,
      category: "Bathroom",
    },
    {
      url: bath7,
      category: "Bathroom",
    },
    {
      url: bath8,
      category: "Bathroom",
    },
    {
      url: bath9,
      category: "Bathroom",
    },
    // Kitchen
    {
      url: kitchenImg1,
      category: "Kitchen",
    },
    {
      url: kitchenImg2,
      category: "Kitchen",
    },
    {
      url: kitchenImg3,
      category: "Kitchen",
    },
    {
      url: kitchenImg4,
      category: "Kitchen",
    },
    // Living Area
    {
      url: livingImg1,
      category: "Living Area",
    },
    {
      url: livingImg2,
      category: "Living Area",
    },
    {
      url: livingImg3,
      category: "Living Area",
    },
    {
      url: livingImg4,
      category: "Living Area",
    },

    // Amenities
    {
      url: amenityImg1,
      category: "Amenities",
    },
    {
      url: amenityImg2,
      category: "Amenities",
    },
    // Views
    {
      url: viewsImg1,
      category: "Views",
    },
    {
      url: viewsImg2,
      category: "Views",
    },
    {
      url: viewsImg3,
      category: "Views",
    },
    {
      url: viewsImg4,
      category: "Views",
    },
    //new images
    {
      url: n1,
      category: "Kitchen",
    },
    {
      url: n2,
      category: "Kitchen",
    },
    {
      url: n3,
      category: "Views",
    },
    {
      url: n4,
      category: "Kitchen",
    },
    {
      url: n5,
      category: "Interior",
    },
    {
      url: n6,
      category: "Views",
    },

    {
      url: n8,
      category: "Views",
    },
    {
      url: n9,
      category: "Views",
    },
    {
      url: n10,
      category: "Views",
    },
    {
      url: n11,
      category: "Views",
    },
    {
      url: n12,
      category: "Views",
    },
    {
      url: n13,
      category: "Views",
    },
    {
      url: n14,
      category: "Views",
    },
    {
      url: n15,
      category: "Views",
    },
    {
      url: n16,
      category: "Views",
    },
    {
      url: n17,
      category: "Views",
    },
    {
      url: n18,
      category: "Living Area",
    },
    {
      url: n19,
      category: "Views",
    },
    {
      url: n20,
      category: "Views",
    },
    {
      url: n21,
      category: "Views",
    },
    {
      url: n22,
      category: "Views",
    },
    {
      url: n23,
      category: "Views",
    },
    {
      url: n24,
      category: "Views",
    },
    {
      url: n25,
      category: "Views",
    },
    {
      url: n26,
      category: "Views",
    },
    {
      url: n27,
      category: "Views",
    },
    {
      url: n28,
      category: "Views",
    },
    {
      url: n29,
      category: "Views",
    },
    {
      url: n30,
      category: "Views",
    },
    {
      url: n31,
      category: "Views",
    },
    {
      url: n32,
      category: "Views",
    },
    {
      url: n33,
      category: "Views",
    },
    {
      url: n34,
      category: "Views",
    },
    {
      url: n35,
      category: "Views",
    },
    {
      url: n36,
      category: "Views",
    },
  ];
  return (
    <>
      <Box sx={style.main}>
        <Container sx={style.container}>
          <Box>
            <Grid container sx={{ justifyContent: "center" }} columnSpacing={2}>
              {data.map((item, index) => {
                return (
                  <Grid key={index} item marginY="2rem">
                    <Chip
                      onClick={() => setCurrent(item)}
                      label={item}
                      variant="outlined"
                      sx={{
                        color: "white",
                        fontSize: 18,
                        fontFamily: "Poppins",
                        cursor: "pointer",
                        background:
                          current === item ? "#3D3B40" : "transparent",
                        ":hover": {
                          background: "#3D3B40",
                        },
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Grid
            container
            spacing={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {images.map((data, index) => {
              if (current === "All Photos" || current === data.category) {
                return (
                  <Grid
                    data-aos="zoom-in-left"
                    data-aos-delay="1200"
                    item
                    lg={4}
                    md={6}
                    xs={12}
                    key={index}
                  >
                    <img
                      alt="oops"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={data.url}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            })}
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default RentalGrid;
const style = {
  main: {
    background: `url(${back}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",

    color: "white",
    paddingY: "2rem",
  },
  container: {
    maxWidth: { lg: "1200px", xl: "1500px" },
  },
  box: {
    width: "100%",
    height: "100%",
  },
  box1: {
    width: "100%",
    height: "100%",
    padding: "5rem",
    display: "flex",

    flexDirection: "column",
  },
  heading: {
    color: "white",
    fontWeight: 700,
    fontSize: "1.8rem",
  },
  Para: {
    color: "white",
    fontWeight: 500,
    fontSize: "18px",
    marginTop: "6rem",
    width: "80%",
    fontFamily: "Poppins",
    textAlign: { lg: "left", xs: "center" },
  },
  gallery: {
    color: "white",
    marginY: "25px",
    fontFamily: "Poppins-Regular",
    fontWeight: 900,
    fontSize: "55px",
    textAlign: "center",
  },
};
