import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import bgHome from "../../Assets/images/backHome.png";
import room from "../../Assets/images/room.png";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
const styles = {
  main: {
    width: "100%",
    background: `url(${bgHome}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingY: "40px",
  },
  absolute: {
    position: "absolute",
    top: 0,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  colr: {
    color: "#FFF",
    fontWeight: "600",
  },
  colr2: {
    color: "#FFF",
    fontSize: "16px",
    fontWeight: "500",
  },
  colr3: {
    color: "#FFF",
    fontSize: "28px",
    fontFamily: "Playfair Display",
    marginBottom: "0.5rem",
  },
  cont: {
    maxWidth: { lg: "1200px", xl: "1500px" },
    paddingY: "40px",
  },
  grd: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "30px",
  },
  col2: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "30px",
  },
  col3: {
    display: "flex",
    flexDirection: "column",
  },
  btn: {
    backgroundColor: "#0082CA",
    paddingY: "10px",
    paddingX: "17px",
    color: "white",
    borderRadius: "25px",
    fontSize: "14px",
    fontFamily: "Poppins",
    transition: "all ease-out 0.5s",
    border: "1px solid #0082CA",
    ":hover": {
      backgroundColor: "white",
      color: "#0082CA",
      border: "1px solid #0082CA",
    },
  },
};
function About() {
  const [ref] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div>
      <Box sx={styles.main}>
        <Container sx={styles.cont}>
          <Grid container columnSpacing={"30px"}>
            <Grid
              item
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div ref={ref}>
                <motion.div>
                  <Box sx={styles.grd}>
                    <Typography
                      sx={[
                        styles.colr,
                        {
                          textAlign: { lg: "left", xs: "center" },
                          fontFamily: "Playfair Display",
                          fontSize: { sm: "3.7rem", xs: "35px" },
                        },
                      ]}
                      variant="h2"
                      data-aos="flip-up"
                    >
                      Grandview Getaway
                    </Typography>
                    <Box sx={styles.img}>
                      <img
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        src={room}
                        style={{ height: "100%", width: "100%" }}
                        alt="img"
                      />
                    </Box>
                  </Box>
                </motion.div>
              </div>
            </Grid>
            <Grid item md={6}>
              <Box sx={styles.col2}>
                <Typography
                  sx={[
                    styles.colr2,
                    { fontFamily: "Poppins", marginY: "30px" },
                  ]}
                >
                  Welcome to Grandview Getaway, your perfect retreat in Lenore,
                  Idaho, where relaxation meets the great outdoors! Nestled atop
                  a hillside, this vacation home offers breathtaking views of
                  the serene Clearwater River, making it an idyllic escape for
                  your next getaway.
                </Typography>
                <div data-aos="fade-left" data-aos-duration="1200">
                  <Typography sx={styles.colr3}>Budget-Friendly:</Typography>
                  <Typography
                    sx={[
                      styles.colr2,
                      { fontFamily: "Poppins", marginY: "30px" },
                    ]}
                  >
                    Discover affordability without compromise at Grand View
                    Getaway. Enjoy an unforgettable vacation that fits your
                    budget perfectly.
                  </Typography>
                  <Typography sx={styles.colr3}>Prime Location:</Typography>
                  <Typography
                    sx={[
                      styles.colr2,
                      { fontFamily: "Poppins", marginY: "30px" },
                    ]}
                  >
                    For those seeking adventure, the Lenore Boat Launch is just
                    a quick 5-minute drive away. Spend your days fishing on the
                    Clearwater River, exploring its tranquil waters, or simply
                    enjoying a leisurely boat ride under the blue Idaho skies.
                    Should you wish to explore the area further, the vibrant
                    city of Lewiston is only 35 minutes west. Discover its
                    charming shops, multiple Wineries, delightful restaurants,
                    Golf courses, Clearwater Casino, and immerse yourself in the
                    local scene. Alternatively, the picturesque town of Orofino
                    is just 20 minutes east, offering more opportunities to
                    embrace nature and adventure. Hire one of our recommended
                    river guides to take you to Dworshak reservoir or down the
                    Clearwater river for some of the best fishing in the USA.
                    There are many Hiking trails to soak in the stunning beauty
                    of the region.
                    <br />
                    <br />
                    Whether you're planning a fishing expedition, a hunting
                    retreat, a romantic couples' getaway, or a memorable trip
                    with your best friends, Grandview Getaway has something to
                    offer everyone. From the cozy interiors that beckon you to
                    unwind to the endless outdoor activities nearby, this
                    vacation home is an escape like no other.
                  </Typography>
                  <Typography sx={styles.colr3}>Book Now:</Typography>
                  <Typography
                    sx={[
                      styles.colr2,
                      { fontFamily: "Poppins", marginY: "30px" },
                    ]}
                  >
                    Join the ranks of ecstatic guests, who have experienced the
                    magic of Grandview Getaway.
                  </Typography>
                  <Box sx={styles.row}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 20,
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <a class="button-2" role="button" href="/plan">
                        Direct Booking
                      </a>
                      <a
                        class="button-2"
                        role="button"
                        target="_blank"
                        rel="noreferrer"
                        href="https://t.vrbo.io/fZwwRWAkzCb"
                      >
                        Book On Vrbo
                      </a>
                      <a
                        class="button-2"
                        role="button"
                        target="_blank"
                        href="https://air.tl/MoXlLSAA"
                        rel="noreferrer"
                      >
                        Book On Airbnb
                      </a>
                    </div>
                  </Box>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

export default About;
