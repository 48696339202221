import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
const AppintmentModal = ({
  open,
  handleClose,
  pakageDetails,
  events,
}) => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phNo: "",
    date: "",
    message: "",
    quantity: 1,
  });
  const [selectedDate, setSelectedDate] = useState({
    startDate: "",
    endDate: "",
  });
  // const [events, setEvents] = useState([]);
  // const [loading, setLoading] = useState(false);

  const onChange = (newDate) => {
    setData({
      ...data,
      date: newDate,
    });

    if (!selectedDate.startDate) {
      setSelectedDate({ startDate: newDate, endDate: "" });
    } else if (!selectedDate.endDate) {
      setSelectedDate({ startDate: selectedDate.startDate, endDate: newDate });
    } else {
      setSelectedDate({ startDate: newDate, endDate: "" });
    }
  };
  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };
  const checkhout = async (look, id, day) => {
    if (!data.name || !data.email || !data.phNo || !data.date) {
      alert("Please fill in all fields");
      return;
    }

    if (selectedDate.startDate && selectedDate.endDate) {
      const startDate = new Date(selectedDate.startDate);
      const endDate = new Date(selectedDate.endDate);
      const timeDiff = Math.abs(endDate - startDate);
      const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      if (day === "1+ Night" && daysDiff > 7) {
        alert("Please Select Different Package your Nights is exceeding");
        return;
      } else if (day === "7+ Night" && (daysDiff > 30 || daysDiff < 7)) {
        alert("Please Select Nights greater then 6 and less then 30");
        return;
      } else {
        try {
          const response = await fetch(
            "https://tcc-server-virid.vercel.app/create-checkout-session",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                lookup_key: look,
                priceId: id,
                quantity: daysDiff,
              }),
            }
          );
          if (response.ok) {
            const body = await response.json();
            postDataToEmail().then(() => {
              window.location.href = body.url;
            });
          } else {
            console.error("Payment failed");
          }
        } catch (error) {
          console.error("Error occurred while making the payment:", error);
        }
      }
    }
  };

  const tileDisabled = ({ date }) => {

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to midnight to avoid time-related issues

    // Check if the date is in the past
    if (date < today) {
      return true;
    }
    // Check if the date is before the selected start date
    if (selectedDate.startDate && date < new Date(selectedDate.startDate)) {
      return true;
    }
    if (selectedDate.endDate && date > new Date(selectedDate.endDate)) {
      return true;
    }
    return events?.some((event) => {
      const startDate = new Date(event.start);
      const endDate = new Date(event.end);
      if (
        selectedDate.startDate &&
        new Date(selectedDate.startDate) < startDate &&
        !selectedDate.endDate &&
        date >= startDate
      ) {
        return true;
      }
      return date >= startDate && date <= endDate;
    });
  };

  const postDataToEmail = async () => {
    return await axios
      .post("https://tcc-server-virid.vercel.app/send-email", data)
      .then(() => { });
  };

  const clearDates = () => {
    setSelectedDate({
      startDate: "",
      endDate: "",
    });
  };
  useEffect(() => {
    tileDisabled(data.date);
  }, [clearDates]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axios.get(
  //         "https://tcc-server-virid.vercel.app/icalendar"
  //       );
  //       const eventsData = response.data.map((event) => ({
  //         title: event.summary,
  //         start: new Date(event.dtstart),
  //         end: new Date(event.dtend),
  //         description: event.description,
  //       }));
  //       setEvents(eventsData);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching events:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ padding: "20px" }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ fontWeight: 700, fontFamily: "Poppins" }}
      >
        BOOK APPOINTMENT
      </DialogTitle>
      {events && events?.length > 0 ? (
        <>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box>
                <TextField
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                  sx={{ width: "100%", marginY: "10px" }}
                  onChange={(e) => {
                    handleChange("name", e.target.value);
                  }}
                />
                <TextField
                  sx={{ width: "100%", marginY: "10px" }}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  onChange={(e) => {
                    handleChange("email", e.target.value);
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  sx={{ width: "100%", marginY: "10px" }}
                  onChange={(e) => {
                    handleChange("phNo", e.target.value);
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Message (Optional)"
                  variant="outlined"
                  sx={{ width: "100%", marginY: "10px" }}
                  onChange={(e) => {
                    handleChange("message", e.target.value);
                  }}
                />
                <Box>
                  <Typography sx={{ fontSize: 11, marginBottom: -1 }}>
                    Check-in Date
                  </Typography>
                  <TextField
                    type="text"
                    variant="outlined"
                    disabled
                    sx={{ width: "100%", marginY: "10px" }}
                    value={selectedDate?.startDate?.toString().slice(0, 15)}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: 11, marginBottom: -1 }}>
                    Check-out Date
                  </Typography>
                  <TextField
                    type="text"
                    variant="outlined"
                    disabled
                    sx={{ width: "100%", marginY: "10px" }}
                    value={selectedDate?.endDate?.toString().slice(0, 15)}
                  />
                </Box>

                <Typography sx={{ cursor: "pointer" }} onClick={clearDates}>
                  Clear Dates
                </Typography>

                <Box className="priceModalCalender">
                  <Calendar
                    onChange={onChange}
                    value={data.date}
                    tileDisabled={tileDisabled}
                  />
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                checkhout(
                  pakageDetails.day,
                  pakageDetails.priceId,
                  pakageDetails.name
                );
              }}
            >
              Check-out
            </Button>
            <Button onClick={handleClose} autoFocus variant="contained">
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

export default AppintmentModal;
