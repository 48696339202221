import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import {
  Link,
} from "react-router-dom";
import logo from "../../Assets/images/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import MobileDrawer from "./MobileDrawer";
function Header() {
  const [active, setActive] = useState("");
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  useEffect(() => {
    const currentUrl = window.location.href;
    setActive(currentUrl.split("/")[3]);
  }, []);
  return (
    <>
      <Box sx={style.main}>
        <Container sx={style.container}>
          <Grid
            container
            sx={{
              alignItems: "center",
              backgroundColor: "#ffffff54",
              backdropFilter: "blur(10px)",
              padding: { md: "1rem 3rem", xs: "0rem 2rem" },
              borderRadius: "100px",
              justifyContent: "space-between",
            }}
          >
            <Grid item lg={2}>
              <Link to="/">
                <Box
                  sx={{
                    height: "90px",
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: "133px", height: "auto" }}
                    src={logo}
                    alt="oops"
                  />
                </Box>
              </Link>
            </Grid>
            <Grid item lg={10}>
              <Box sx={style.linksBox}>
                <Typography sx={style.itemLink}>
                  <Link
                    style={{
                      listStyle: "none",
                      color: active === "" ? "#00B0D2" : "inherit",
                      textDecoration: "none",
                    }}
                    to="/"
                  >
                    Home
                  </Link>
                </Typography>
                <Typography sx={style.itemLink}>
                  <Link
                    style={{
                      listStyle: "none",
                      color: active === "Vacation" ? "#00B0D2" : "inherit",
                      textDecoration: "none",
                    }}
                    to="/Vacation"
                  >
                    Vacation Home
                  </Link>
                </Typography>
                <Typography sx={style.itemLink}>
                  <Link
                    style={{
                      listStyle: "none",
                      color: active === "events" ? "#00B0D2" : "inherit",
                      textDecoration: "none",
                    }}
                    to="/events"
                  >
                    Entertainment & Recomandations
                  </Link>
                </Typography>
                <Typography sx={style.itemLink}>
                  <Link
                    style={{
                      listStyle: "none",
                      color: active === "plan" ? "#00B0D2" : "inherit",
                      textDecoration: "none",
                    }}
                    to="/plan"
                  >
                    Plan
                  </Link>
                </Typography>
                <Typography sx={style.itemLink}>
                  <Link
                    style={{
                      listStyle: "none",
                      color: active === "Rental" ? "#00B0D2" : "inherit",
                      textDecoration: "none",
                    }}
                    to="/Rental"
                  >
                    Gallery
                  </Link>
                </Typography>
                <Typography sx={style.itemLink}>
                  <Link
                    style={{
                      listStyle: "none",
                      color: active === "specials" ? "#00B0D2" : "inherit",
                      textDecoration: "none",
                    }}
                    to="/specials"
                  >
                    Specials
                  </Link>
                </Typography>
                {/* <Button
                  sx={style.btn}
                  onClick={() => {
                    navigate("/Contact");
                  }}
                >
                  Contact Us
                </Button> */}
              </Box>
              <Box sx={style.showMenu}>
                <GiHamburgerMenu
                  onClick={toggleDrawer(true)}
                  style={{ color: "#00B0D2", fontSize: 35 }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <MobileDrawer
          toggleDrawer={toggleDrawer}
          open={open}
          setOpen={setOpen}
        />
      </Box>
    </>
  );
}

export default Header;
const style = {
  main: {
    margin: 0,
    padding: "25px 0",
    position: "absolute",
    width: "100%",
    zIndex: 2,
  },
  container: {
    maxWidth: { lg: "1200px", md: "1100px", xl: "1500px" },
  },
  linksBox: {
    color: "white",
    width: "100%",
    height: "100%",
    display: { lg: "flex", xs: "none" },
    flexDirection: "row",
    gap: "25px",
    alignItems: "center",
    justifyContent: "end",
  },
  itemLink: {
    fontWeight: 500,
    fontSize: "18px",
    transition: "all ease-in-out 0.5s",
    fontFamily: "Poppins",
    borderBottom: "1px solid transparent",
    ":hover": {
      color: "#1EB2CD",
      borderBottom: "1px solid #1EB2CD",
      transition: "",
      cursor: "pointer",
    },
  },

  btn: {
    fontFamily: "Poppins",
    color: "white",
    fontSize: "14px",
    fontWeight: 700,
    padding: "10px 20px",
    border: "2px solid white",
    borderRadius: "100px",
    transition: "all ease-in-out 0.5s",
    ":hover": {
      color: "black",
      backgroundColor: "white",
    },
  },
  showMenu: {
    display: { xs: "flex", lg: "none" },
  },
};
