import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Rating, Typography } from "@mui/material";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css/navigation";
import EastIcon from "@mui/icons-material/East";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SwiperCore from "swiper";
import "swiper/css";
const styles = {
  main: {
    paddingY: "100px",
    position: "relative",
  },
  slid: {
    display: "flex",
    justifyContent: "center",
    gap: "30px",
    alignItems: "center",
    padding: "10px",
    borderRadius: "50px",
    fontFamily: "Poppins",
  },
  contentBox: {
    position: "relative",
    padding: "1rem",
    borderRadius: "10px",
  },
  staticHeading: {
    fontFamily: "Poppins",
    color: "#0082CA",
    fontSize: "18px",
    paddingBottom: "12px",
  },
  Heading: {
    fontFamily: "Playfair Display",
    fontSize: "40px",
    paddingBottom: "15px",
  },
  ratingBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  authorName: {
    fontFamily: "Poppins",
    color: "#0082CA",
    fontSize: "28px",
  },
};
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
function Testimonials() {
  const swiperRef = useRef(null);
  const handleNext = () => {
    if (swiperRef.current !== null) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current !== null) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const data = [
    {
      head: "Beautiful views & comfortable home",
      desc: `"Everything in the house was very well thought out and comfortable. Full length mirrors in the spare bedrooms were the only thing that I heard that could be added, but really it was wonderful!"`,
      author: "Tracy H",
    },
    {
      head: "Thanksgiving getaway",
      desc: `"We all loved it. Beautiful views, the most comfortable beds ever, the entire home was wonderful! One of the best VRBO experience we ever had."`,
      author: "Debra M.",
    },
    {
      head: "It was perfect!",
      desc: `"We loved the house and surrounding area. The host was amazing!"`,
      author: "paula t.",
    },
    {
      head: "Lenore Vacation 2023",
      desc: "Very nice and tidy place, host communicates very well. House is 1.9 miles from the river if you go for fishing.",
      author: "Steve C.",
    },
    {
      head: "Excellent stay",
      desc: "Very nice place, well equipped. Everything as advertised!!! Spectacular view of the Clearwater River.",
      author: "adam r.",
    },
    {
      head: "Beautiful views & comfortable home",
      desc: "Everything in the house was very well thought out and comfortable. Full length mirrors in the spare bedrooms were the only thing that I heard that could be added, but really it was wonderful!",
      author: "Tracy H.",
    },
    {
      head: "Grandview Getaway",
      desc: "Fantastic view, well appointed, immaculate, extremely well supplied and things in excellent condition. Perfect for our family of 6.",
      author: "Judy L.",
    },
    {
      head: "Comfy with a view!",
      desc: "Quick stay during a 3-day motorcycle tour for 3 friends. Barbecued out on the deck and spent most of the time enjoying the company and view from the deck. Beds and rooms were comfy and clean, kitchen was set up well and there were local eggs in the fridge for the morning. We couldn’t get the internet to work but that didn’t really bother us and we didn’t try to fix it or feel the need to bother the host about it. Overall a great spot for a stopover and nice bookend on our trip through Idaho. Thank you!",
      author: "BJ C.",
    },
  ];
  return (
    <Box sx={styles.main}>
      <Swiper
        ref={swiperRef}
        spaceBetween={90}
        navigation={false} // Disabling Swiper's default navigation
        // pagination={{ clickable: true }}
        autoplay
        loop
        centeredSlides={true}
        breakpoints={{
          // When window width is >= 768px
          768: {
            slidesPerView: 1,
          },
          // When window width is >= 992px
          1478: {
            slidesPerView: 3,
          },
        }}
      >
        {data.map(({ head, desc, author }) => (
          <SwiperSlide>
            <Box sx={styles.slid}>
              <Box sx={styles.contentBox}>
                <Typography sx={styles.staticHeading}>Testimonial</Typography>
                <Typography sx={styles.Heading} className="colon">
                  {head}
                </Typography>
                <Typography
                  sx={{ fontFamily: "Poppins", paddingBottom: "15px" }}
                >
                  {desc}
                </Typography>
                <Box sx={styles.ratingBox}>
                  <Typography sx={styles.authorName}>{author}</Typography>
                  <Rating name="simple-controlled" value={5} />
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        sx={{
          color: "#FFF",
          fontSize: "80px",
          position: "absolute",
          bottom: 150,
          left: 450,

          zIndex: 1000,
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          backgroundColor: "#333",
          display: { sm: "flex", xs: "none" },
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handlePrev}
      >
        <EastIcon sx={{ fontSize: "30px", color: "#FFF", cursor: "pointer" }} />
      </Box>
      <Box
        sx={{
          color: "#FFF",
          fontSize: "40px",
          position: "absolute",
          bottom: 150,
          left: 380,
          zIndex: 1000,
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          backgroundColor: "#0082CA",
          display: { sm: "flex", xs: "none" },
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleNext}
      >
        <KeyboardBackspaceIcon sx={{ fontSize: "30px", cursor: "pointer" }} />
      </Box>
    </Box>
  );
}

export default Testimonials;
