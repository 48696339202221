import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import image1 from "../../Assets/images/01.png";
import image2 from "../../Assets/images/grid2.png";
import image3 from "../../Assets/images/grid3.png";
import image4 from "../../Assets/images/grid4.png";
import image5 from "../../Assets/images/yoga.jpg";
import image6 from "../../Assets/images/grid6.png";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
function MyCard() {
  const [ref] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  return (
    <Box sx={style.main}>
      <Grid container spacing={2}>
        <Grid item md={4} sm={6}>
          <div ref={ref} style={{}}>
            <motion.div>
              <Box
                sx={style.box1}
                data-aos="zoom-in-up"
                data-aos-duration="1200"
                data-aos-easing="ease-in-sine"
              >
                <img
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                  alt="alt"

                  src={image1}
                />

                <Box sx={style.textContent}>
                  <Box sx={{ paddingX: "31px" }}>
                    <Typography sx={style.head}>Couples Getaway:</Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: "white",

                        fontWeight: { lg: 600, md: 500 },
                        fontFamily: "Poppins",
                        marginY: { lg: "30px", md: "20px" },
                      }}
                    >
                      What’s more romantic than time and space to truly pay
                      attention to your partner. Enjoy sitting out on the
                      spacious deck overlooking the river, watching the sun go
                      down over the hillside with the one you love.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </motion.div>
          </div>
          <Box
            sx={style.box2}
            data-aos="zoom-in-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
            style={{ marginTop: "1rem" }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt="alt"

              src={image2}
            />
            <Box sx={style.textContent}>
              <Box sx={{ paddingX: "31px" }}>
                <Typography sx={style.head}>Best Friends trips:</Typography>
                <Typography sx={style.content}>
                  Nothing better than getting the girls or the guys together for
                  a week of fun, food and some of the best gut laughs you will
                  ever experience.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* second coloumn */}
        <Grid item md={4} sm={6}>
          <Box
            sx={style.box3}
            data-aos="zoom-in-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt="img"
              src={image3}
            />
            <Box sx={style.textContent}>
              <Box sx={{ paddingX: "31px" }}>
                <Typography sx={style.head}>
                  Hunting and Fishing Trips:
                </Typography>
                <Typography sx={style.content}>
                  We are calling all outdoor enthusiasts! Experience the thrill
                  of fishing the Clearwater and hunting central Idaho. Bring
                  back your catch of the day, throw it on the Traeger smoker and
                  have yourself a feast.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={style.box4}
            style={{ marginTop: "1rem" }}
            data-aos="zoom-in-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt="alt"
              src={image4}
            />
            <Box sx={style.textContent}>
              <Box sx={{ paddingX: "31px" }}>
                <Typography sx={style.head}>Spiritual Retreats:</Typography>
                <Typography sx={style.content}>
                  Remove yourself from the daily grind and reconnect with your
                  higher self. With room for 6 guests, grab your favorite people
                  and find inner peace and serenity at Grandview Getaway.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* third coloumn */}
        <Grid
          item
          md={4}
          xs={12}
          sx={{
            display: { md: "block", sm: "flex" },
            flexDirection: "row",
            alignItems: "start",
            gap: "10px",
          }}
        >
          <Box
            sx={style.box5}
            data-aos="zoom-in-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt="alt"

              src={image5}
            />
            <Box sx={style.textContent}>
              <Box sx={{ paddingX: "31px" }}>
                <Typography sx={style.head}>Yoga Groups:</Typography>
                <Typography style={style.content}>
                  With a spectacular view like this, there is no better, more
                  inspiring place to lay down your mat for a sunrise or sunset
                  yoga class. Gather your best gym friends for an unforgettable
                  adventure. Unwind, rejuvenate, and reconnect with your mind,
                  body, and spirit.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={style.box6}
            style={{ marginY: { md: "1rem", xs: "0px" } }}
            data-aos="zoom-in-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt="alt"

              src={image6}
            />
            <Box sx={style.textContent}>
              <Box sx={{ paddingX: "31px" }}>
                <Typography sx={style.head}>seasonal Beauty:</Typography>
                <Typography style={style.content}>
                  Immerse yourself in the breathtaking beauty of all seasons.
                  Nature's marvels awaits you.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const style = {
  head: {
    fontFamily: "Playfair Display",
    textAlign: "center",
    fontSize: { lg: "24px", md: "20px" },
    textTransform: "capitalize",
  },
  content: {
    textAlign: "center",
    fontWeight: { lg: 600, md: 500 },
    fontFamily: "Poppins",
    marginY: { lg: "30px", md: "20px" },
  },
  main: {
    width: "100%",
    height: "100%",
    marginTop: "1rem",
  },
  box1: {
    position: "relative",
    width: "100%",
  },
  box2: {
    width: "100%",
    position: "relative",
  },
  box3: {
    width: "100%",
    height: "60%",
    position: "relative",
  },
  box4: {
    width: "100%",
    height: "38.5%",
    position: "relative",
  },
  box5: {
    width: "100%",
    position: "relative",
    marginBottom: "10px",
  },
  box6: {
    width: "100%",
    position: "relative",
  },
  cardImg: {
    width: "100%",
  },
  textContent: {
    position: "absolute",
    top: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "20px",
    textShadow: "2px 2px 4px #000",
    fontFamily: "Poppins, sans-serif",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 9999,
    color: "white",
    fontWeight: 900,

    backgroundColor: " rgb(0, 0, 0, 0.4)",
  },
};

export default MyCard;
